<script>
    /* (c) Copyright 2020 Brian Hann (c0bra).
     See: https://github.com/c0bra/svelma/blob/master/src/components/Toast/Toast.svelte */
    // noinspection ES6CheckImport
    import Notice, {filterProps} from './Notice.svelte';

    /* This variable manages the close action */
    let active;

    // Handles the click event
    function handleClick() {
        if ($$props.hasClick) {
            active = false;
        }
    }
</script>

<Notice {...filterProps($$props)} bind:active>
    <div class="toast {$$props.bgClass} noselect" class:toast-pointer={$$props.hasClick}
         role="alert"
         on:click={handleClick}
         on:keydown
    >
        <div class="toast-text relative">
            {@html $$props.message}
            {#if $$props.hasClose}
                <i class="absolute top-0 right-0 toast-is-close fas fa-times-circle"></i>
            {/if}
        </div>
    </div>
</Notice>
