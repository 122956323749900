<!-- This file is generated by Sapper — do not edit it! -->
<script>
	import { setContext, afterUpdate } from 'svelte';
	import { CONTEXT_KEY } from './shared';
	import Layout from '../../../routes/_layout.svelte';
	import Error from '../../../routes/_error.svelte';

	export let stores;
	export let error;
	export let status;
	export let segments;
	export let level0;
	export let level1 = null;
	export let level2 = null;
	export let level3 = null;
	export let level4 = null;
	export let notify;

	afterUpdate(notify);
	setContext(CONTEXT_KEY, stores);
</script>

<Layout segment="{segments[0]}" {...level0.props}>
	{#if error}
		<Error {error} {status}/>
	{:else}
		<svelte:component this="{level1.component}" segment="{segments[1]}" {...level1.props}>
			{#if level2}
				<svelte:component this="{level2.component}" segment="{segments[2]}" {...level2.props}>
					{#if level3}
						<svelte:component this="{level3.component}" segment="{segments[3]}" {...level3.props}>
							{#if level4}
								<svelte:component this="{level4.component}" {...level4.props}/>
							{/if}
						</svelte:component>
					{/if}
				</svelte:component>
			{/if}
		</svelte:component>
	{/if}
</Layout>