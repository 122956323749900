<script context="module">
    /* (c) Copyright 2020 Brian Hann (c0bra).
     See: https://github.com/c0bra/svelma/blob/master/src/components/Notice.svelte */
    const allowedProps = ['active', 'position', 'duration'];

    // noinspection JSUnusedGlobalSymbols
    export function filterProps (props) {
        const newProps = {};
        Object.keys(props).forEach(key => {
            if (allowedProps.includes(key)) newProps[key] = props[key];
        });
        return newProps;
    }
</script>

<script>
    import {createEventDispatcher, onMount, tick} from 'svelte';
    import {fly, fade} from 'svelte/transition';
    // noinspection ES6CheckImport
    import Notices, {notices} from './Notices';

    const dispatch = createEventDispatcher();

    export let active = true;
    export let position = 'is-top';
    export let duration = 2000;
    export let transitionOut = true;

    let el;
    let parent;
    let timer;
    $: transitionY = ~position.indexOf('is-top') ? -200 : 200;

    export function close () {
        active = false;
    }

    function remove () {
        clearTimeout(timer);
        // Just making sure
        active = false;
        dispatch('destroyed');
    }

    async function setupContainers () {
        await tick;
        if (!notices.top) {
            notices.top = new Notices({
                target: document.body,
                props: {
                    position: 'top',
                },
            });
        }
        if (!notices.bottom) {
            notices.bottom = new Notices({
                target: document.body,
                props: {
                    position: 'bottom',
                },
            });
        }
    }

    function chooseParent () {
        parent = notices.top;
        if (position && position.indexOf('is-bottom') === 0) parent = notices.bottom;
        // noinspection JSUnresolvedFunction
        parent.insert(el);
    }

    onMount(async () => {
        await setupContainers();
        chooseParent();
        timer = setTimeout(() => {
            close();
        }, duration);
    });
</script>

{#if active}
    <div
            class="toast-notice {position}"
            aria-hidden="false"
            in:fly={{ y: transitionY }}
            out:fade={{ duration: transitionOut ? 400 : 0 }}
            on:outroend={remove}
            bind:this={el}>
        <slot/>
    </div>
{/if}
