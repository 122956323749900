<script>
    export let advisor;
</script>

<div class="-mt-1 text-prim-600 text-3 tracking-wider flex-row-bottom">
    <div class="font-2">
        {advisor.fullName()}
    </div>
    <i class="ml-4 text-gray-300 fas fa-calendar-alt"></i>
    <div class="ml-4 font-2">
        Planning
    </div>
    {#if advisor.phone.trim() || advisor.station.trim()}
        <div class="ml-4 text-1 tracking-normal flex-row-bottom">
            {#if advisor.phone.trim()}
                <i class="text-gray-300 fas fa-mobile-alt"></i>
                <div class="ml-2">
                    {advisor.phone.trim()}
                </div>
            {/if}
            {#if advisor.station.trim()}
                <div class="ml-2 text-gray-300">
                    Poste
                </div>
                <div class="ml-2">
                    {advisor.station.trim()}
                </div>
            {/if}
        </div>
    {/if}
</div>
