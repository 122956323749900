<script context="module">
    /* (c) Copyright 2020 Brian Hann (c0bra).
     See: https://github.com/c0bra/svelma/blob/master/src/components/Notices.svelte */
    // noinspection JSUnusedGlobalSymbols
    export const notices = {};
</script>

<script>
    export let position = 'top';

    let container;
    let positionClass;
    $: positionClass = position === 'top' ? 'is-top' : 'is-bottom';

    export function insert (el) {
        // noinspection SpellCheckingInspection
        container.insertAdjacentElement('afterbegin', el);
    }
</script>

<!--suppress CheckEmptyScriptTag -->
<div bind:this={container} class="toast-notices {positionClass}"/>
